export const SINGLE_SIDE_VAULT = '0x8dC8F2D579A6597c7A0E723299062387ae7d8633'
export const PSDN_TOKEN = '0xA5B947687163FE88C3e6af5b17Ae69896F4abccf'
export const H2O_TOKEN = '0x0642026e7f0b6ccac5925b4e7fa61384250e1701'
export const WETH_TOKEN = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'
export const USDC_TOKEN = '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'
export const OCEAN_TOKEN = '0x967da4048cd07ab37855c090aaf366e4ce1b9f48'
export const RAI_TOKEN = '0x03ab458634910aad20ef5f1c8ee96f1d6ac54919'
export const THREECRV_TOKEN = '0x6c3f90f043a72fa612cbac8115ee7e52bde6e490'

// Gamma Vaults
export const PSDN_WETH_VAULT = '0x2Ae08F2cBB9A9e2d4609c487Ecb1B6308718D5e8'
export const PSDN_WETH_TOKEN = '0x0ec4a47065bf52e1874d2491d4deeed3c638c75f'

export const H2O_WETH_VAULT = '0x31FaeAf21C6c87ff69336455fb58Ef374faa68Ef'
export const H2O_WETH_TOKEN = '0xeaa629224b3ab2b42b42fddb53a1b51351acbe4f'

export const H2O_USDC_VAULT = '0x484787Baf344005c45E912f3117B6D7fA7507094'
export const H2O_USDC_TOKEN = '0xe1ae05518a67ebe7e1e08e3b22d905d6c05b6c0f'

export const H2O_OCEAN_VAULT = '0x54c2E1C017941484f28386cc5e90a7dC18441bf8'
export const H2O_OCEAN_TOKEN = '0x7d89593e1f327f06579faef15e88aadfae51713a'

export const OCEAN_PSDN_VAULT = '0x2C0C00AB5442D252459287EbB238cAb22f7e1780'
export const OCEAN_PSDN_TOKEN = '0x9196617815d95853945cd8f5e4e0bb88fdfe0281'

// Reflexer Vaults
export const RAI_THREECRV_VAULT = '0x0210B1ed07Df594FD3aAc32832a6E1690554716a'
export const RAI_THREECRV_TOKEN = '0x6BA5b4e438FA0aAf7C1bD179285aF65d13bD3D90'
export const RAI_THREECRV_POOL = '0x618788357d0ebd8a37e763adab3bc575d54c2c7d'

export const FLX_ETH_VAULT = '0xd8031d053A2e2370C5A510c9a94aFF29565a7365'
export const FLX_ETH_TOKEN = '0xd6F3768E62Ef92a9798E5A8cEdD2b78907cEceF9'

export const FLX_VAULT = '0x9b3f79495649ff97a38c11cb23a2d5d089f06585'
export const FLX_TOKEN = '0x6243d8CEA23066d098a15582d81a598b4e8391F4'

export const MERKLE_DISTRIBUTOR = '0xf3D05E481e1f7BAAA32759E3218e8cce270f4A50'
export const MERKLE_DISTRIBUTOR_V2 =
    '0xD49c1381f54E87e0E69735C103429444a400e575'
