import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

interface Props {
    onChange: (val: string) => void
}

const EditSafeNameContent = ({ onChange }: Props) => {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        onChange(val)
    }
    return (
        <>
            <CustomInput
                placeholder={'Enter new name'}
                type={'text'}
                onChange={handleChange}
                disabled={false}
                minLength={1}
            />
        </>
    )
}

export default EditSafeNameContent
const CustomInput = styled.input`
    font-size: ${(props) => props.theme.font.small};
    transition: all 0.3s ease;
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.border};
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.secondary};
    line-height: 21px;
    outline: none;
`
