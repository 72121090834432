import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import store, { useStoreActions, useStoreState } from '../store'
import Brand from './Brand'
import Button from './Button'
import { newTransactionsFirst, returnWalletAddress } from '../utils/helper'
import { useWeb3React } from '@web3-react/core'
import { isTransactionRecent } from '../hooks/TransactionHooks'
import NavLinks from './NavLinks'
import PSDNLogoSmall from './Icons/PSDNLogoSmall'
import useGeb from '../hooks/useGeb'
import { ethers } from 'ethers'
import { NETWORK_ID } from '../connectors'

const Navbar = () => {
    const {
        transactionsModel: transactionsState,
        connectWalletModel: connectWalletState,
        settingsModel: settingsState,
    } = useStoreState((state) => state)

    const { transactions } = transactionsState

    const { popupsModel: popupsActions } = useStoreActions((state) => state)
    const { active, account, chainId } = useWeb3React()
    const geb = useGeb()

    const getProtoBalance = async () => {
        if (!account) {
            return
        }

        const balance = await geb.contracts.protocolToken.balanceOf(account)
        const formattedBalance = ethers.utils.formatEther(balance)

        if (chainId && balance) {
            store.dispatch.connectWalletModel.updateProtBalance({
                chainId,
                balance: formattedBalance,
            })
        }
    }

    useEffect(() => {
        if (account && geb && chainId) {
            getProtoBalance()
        }

        // eslint-disable-next-line
    }, [account, geb, chainId])

    const handleWalletConnect = () => {
        if (active && account) {
            return popupsActions.setIsConnectedWalletModalOpen(true)
        }
        return popupsActions.setIsConnectorsWalletOpen(true)
    }

    const sortedRecentTransactions = useMemo(() => {
        const txs = Object.values(transactions)
        return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
    }, [transactions])

    const pending = sortedRecentTransactions
        .filter((tx) => !tx.receipt)
        .map((tx) => tx.hash)

    const hasPendingTransactions = !!pending.length
    return (
        <Container>
            <Left>
                <Brand isLight={settingsState.isLightTheme} />
            </Left>
            <HideMobile>
                <NavLinks />
            </HideMobile>
            <RightSide>
                {active && account ? (
                    <FLXButton
                        data-test-id="flx-btn"
                        onClick={() =>
                            popupsActions.setIsDistributionsModalOpen(true)
                        }
                    >
                        <Balance>
                            {Number(
                                connectWalletState.protBalance[NETWORK_ID]
                            ).toFixed(4)}
                        </Balance>
                        <FLXInfo>
                            <LogoBox>
                                <PSDNLogoSmall />
                            </LogoBox>
                            PSDN
                        </FLXInfo>
                    </FLXButton>
                ) : null}
                <BtnContainer>
                    <Button
                        id="web3-status-connected"
                        isLoading={hasPendingTransactions}
                        onClick={handleWalletConnect}
                        text={
                            active && account
                                ? hasPendingTransactions
                                    ? `${pending.length} Pending`
                                    : returnWalletAddress(account)
                                : 'connect_wallet'
                        }
                    />
                </BtnContainer>

                <MenuBtn onClick={() => popupsActions.setShowSideMenu(true)}>
                    <RectContainer>
                        <Rect />
                        <Rect />
                        <Rect />
                    </RectContainer>
                </MenuBtn>
            </RightSide>
        </Container>
    )
}

export default Navbar

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height 1px;
        background: linear-gradient(90deg, rgba(50, 163, 220, 0) 0%, #32A3DC 49.48%, rgba(50, 163, 220, 0) 100%);
    }
    padding: 20px 40px;
    margin-bottom: 10px;
    background: ${(props) => props.theme.colors.background};
    ${({ theme }) => theme.mediaWidth.upToSmall`
     padding: 0 20px;
  `}
`

const MenuBtn = styled.div`
    margin-right: -20px;
    width: 60px;
    height: 60px;
    align-items: center;
    justify-content: center;
    display: none;
    border-left: 1px solid ${(props) => props.theme.colors.border};
    cursor: pointer;
    &:hover {
        div {
            div {
                background: ${(props) => props.theme.colors.gradient};
            }
        }
    }
    ${({ theme }) => theme.mediaWidth.upToLarge`
    display: flex;
  `}
`

const BtnContainer = styled.div`
    display: flex;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `}

    svg {
        stroke: white;
        position: relative;
        top: 2px;
        margin-right: 5px;
    }
    #web3-status-connected {
        padding: 9px 24px;
    }
`

const RectContainer = styled.div``

const Rect = styled.div`
    width: 15px;
    border-radius: 12px;
    height: 3px;
    margin-bottom: 2px;
    background: ${(props) => props.theme.colors.secondary};
    transition: all 0.3s ease;
    &:last-child {
        margin-bottom: 0;
    }
`

const RightSide = styled.div`
    display: flex;
    align-items: center;
`

const HideMobile = styled.div`
    ${({ theme }) => theme.mediaWidth.upToLarge`
    display: none;
  `}
`

const Left = styled.div`
    min-width: 194px;
    display: flex;
    align-items: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width:auto;
  `}
`

const FLXButton = styled.button`
    box-shadow: none;
    border: 0;
    background: transparent;
    display: flex;
    padding: 0;
    outline: none;
    align-items: center;
    font-weight: bold;
    height: 40px;
    line-height: 24px;
    position: relative;
    cursor: pointer;
    margin-right: 15px;
`
const Balance = styled.div`
    color: white;
    position: relative;
    font-size: 18px;
    height: 40px;
    padding: 0 10px;
    min-width: 50px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-right: -10px;
`

const FLXInfo = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    color: #fff;
    // border-radius: 0 4px 4px 0;
    padding: 0 10px;
    font-size: ${(props) => props.theme.font.medium};
`
const LogoBox = styled.div`
    margin: 0 5px;
    display: flex;
    align-items: center;
`

// const ThemeBtn = styled.button`
//     background: transparent;
//     box-shadow: none;
//     outline: none;
//     cursor: pointer;
//     border: 0;
//     padding: 9px 10px;
//     margin: 0 0 0 15px;
//     line-height: normal;
//     border-radius: ${(props) => props.theme.global.borderRadius};
//     transition: all 0.3s ease;
//     position: relative;
//     svg {
//         width: 20px;
//         height: 20px;
//         display: block;
//         color: silver;
//     }

//     &:hover {
//         // background: ${(props) => props.theme.colors.secondary};
//         svg {
//             color: ${(props) => props.theme.colors.border};
//         }
//     }

//     ${({ theme }) => theme.mediaWidth.upToSmall`
//    margin-right:15px;
//   `}
// `
