import React, { Suspense, useEffect } from 'react'
import i18next from 'i18next'
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { I18nextProvider } from 'react-i18next'
import ErrorBoundary from './ErrorBoundary'
import { useStoreState } from './store'
import { darkTheme } from './utils/themes/dark'
import { lightTheme } from './utils/themes/light'
import { Theme } from './utils/interfaces'
import OnBoarding from './containers/OnBoarding'
import { initI18n } from './utils/i18n'
import GlobalStyle from './GlobalStyle'
import Shared from './containers/Shared'
import Web3ReactManager from './components/Web3ReactManager'
import SafeDetails from './containers/OnBoarding/SafeDetails'

// import Privacy from './containers/Privacy'
import CustomToast from './components/CustomToast'
import Auctions from './containers/Auctions'
import GoogleTagManager from './components/Analytics/GoogleTagManager'
import { SHOW_AUCTIONS } from './utils/constants'
import SafeSaviour from './containers/OnBoarding/SafeSaviour'
// import Staking from './containers/Earn/Staking'
// import News from './containers/News'
import Airdrop from './containers/Airdrop'
// import Incentives from './containers/Earn/Incentives'
// import netlifyIdentity from 'netlify-identity-widget'
// Toast css

declare module 'styled-components' {
    export interface DefaultTheme extends Theme {}
}
// const netlifyAuth = {
//     isAuthenticated: false,
//     user: null,
//     authenticate(callback: any) {
//         netlifyIdentity.open()
//         netlifyIdentity.on('login', (user) => {
//             this.isAuthenticated = true
//             this.user = user as any
//             callback(user)
//         })
//     },
//     signout(callback: any) {
//         this.isAuthenticated = false
//         netlifyIdentity.logout()
//         netlifyIdentity.on('logout', () => {
//             this.user = null
//             callback()
//         })
//     },
// }

// function PrivateRoute({ component: Component, ...rest }: any) {
//     return (
//         <Route
//             {...rest}
//             render={(props) =>
//                 true ? (
//                     <Component {...props} />
//                 ) : (
//                     <Redirect
//                         to={{
//                             pathname: '/login',
//                             state: { from: props.location },
//                         }}
//                     />
//                 )
//             }
//         />
//     )
// }

// class Login extends React.Component {
//     state = { redirectToReferrer: false }

//     login = () => {
//         netlifyAuth.authenticate(() => {
//             this.setState({ redirectToReferrer: true })
//         })
//     }

//     render() {
//         let { from } = (this.props as any).location.state || {
//             from: { pathname: '/' },
//         }
//         let { redirectToReferrer } = this.state

//         if (redirectToReferrer) return <Redirect to={from} />

//         return (
//             <div>
//                 <p>You must log in to view the page at {from.pathname}</p>
//                 <button onClick={this.login}>Log in</button>
//             </div>
//         )
//     }
// }

const App = () => {
    const { settingsModel: settingsState } = useStoreState((state) => state)

    const { isLightTheme, lang, bodyOverflow } = settingsState

    useEffect(() => {
        initI18n(lang)
    }, [lang])

    return (
        <I18nextProvider i18n={i18next}>
            <ThemeProvider theme={isLightTheme ? lightTheme : darkTheme}>
                <GlobalStyle bodyOverflow={bodyOverflow} />
                <CustomToast />
                <ErrorBoundary>
                    <Router>
                        <Shared>
                            <Suspense fallback={null}>
                                <Route component={GoogleTagManager} />
                                <Web3ReactManager>
                                    <Switch>
                                        {/* <Route
                                            exact
                                            strict
                                            path="/login"
                                            component={Login}
                                        /> */}
                                        {SHOW_AUCTIONS &&
                                        SHOW_AUCTIONS === '1' ? (
                                            <Route
                                                exact
                                                strict
                                                component={Auctions}
                                                path={'/auctions/:auctionType?'}
                                            />
                                        ) : null}
                                        {/* <Route
                                            exact
                                            strict
                                            component={Staking}
                                            path={'/earn/staking'}
                                        /> */}
                                        {/* <Route
                                            exact
                                            strict
                                            component={Privacy}
                                            path={'/privacy'}
                                        /> */}

                                        {/* <Route
                                            exact
                                            strict
                                            component={News}
                                            path={'/news'}
                                        /> */}

                                        <Route
                                            exact
                                            strict
                                            component={Airdrop}
                                            path={'/airdrop'}
                                        />

                                        {/* <PrivateRoute
                                            exact
                                            strict
                                            component={Incentives}
                                            path={'/earn/incentives'}
                                        /> */}
                                        <Route
                                            exact
                                            strict
                                            component={SafeSaviour}
                                            path={'/safes/:id/saviour'}
                                        />
                                        <Route
                                            exact
                                            component={SafeDetails}
                                            path={'/safes/:id'}
                                        />
                                        <Route
                                            exact
                                            strict
                                            component={OnBoarding}
                                            path={'/:address'}
                                        />
                                        <Route
                                            exact
                                            strict
                                            component={OnBoarding}
                                            path={'/'}
                                        />

                                        <Redirect from="*" to="/" />
                                    </Switch>
                                </Web3ReactManager>
                            </Suspense>
                        </Shared>
                    </Router>
                </ErrorBoundary>
            </ThemeProvider>
        </I18nextProvider>
    )
}

export default App
