import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Arrow from './Icons/Arrow'
import Loader from './Loader'

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
    text?: string
    onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void
    dimmed?: boolean
    dimmedNormal?: boolean
    withArrow?: boolean
    disabled?: boolean
    isLoading?: boolean
    dimmedWithArrow?: boolean
    isBordered?: boolean
    arrowPlacement?: string
    children?: ReactNode
}

const Button = ({
    text,
    onClick,
    dimmed,
    dimmedNormal,
    withArrow,
    disabled,
    isLoading,
    dimmedWithArrow,
    isBordered,
    arrowPlacement = 'left',
    children,
    ...rest
}: Props) => {
    const { t } = useTranslation()

    const returnType = () => {
        if (dimmed) {
            return (
                <DimmedBtn {...rest} disabled={disabled} onClick={onClick}>
                    {text && t(text)}
                </DimmedBtn>
            )
        }

        if (dimmedWithArrow) {
            return (
                <DimmedBtn {...rest} disabled={disabled} onClick={onClick}>
                    {arrowPlacement === 'left' ? (
                        <img
                            src={require('../assets/dark-arrow.svg')}
                            alt={''}
                        />
                    ) : null}
                    {text && t(text)}
                    {arrowPlacement === 'right' ? (
                        <img
                            className="rotate"
                            src={require('../assets/dark-arrow.svg')}
                            alt={''}
                        />
                    ) : null}
                </DimmedBtn>
            )
        } else if (withArrow) {
            return (
                <ArrowBtn {...rest} disabled={disabled} onClick={onClick}>
                    <span>{text && t(text)}</span> <Arrow />
                </ArrowBtn>
            )
        } else if (isBordered) {
            return (
                <BorderedBtn {...rest} disabled={disabled} onClick={onClick}>
                    <Inner> {text && t(text)}</Inner>
                </BorderedBtn>
            )
        } else {
            return (
                <Container
                    {...rest}
                    className={dimmedNormal ? 'dimmedNormal' : ''}
                    disabled={disabled}
                    isLoading={isLoading}
                    onClick={onClick}
                >
                    {text && t(text)}
                    {children || null}
                    {isLoading && <Loader inlineButton />}
                </Container>
            )
        }
    }
    return returnType()
}

export default React.memo(Button)

const Container = styled.button<{ isLoading?: boolean }>`
    outline: none;
    cursor: pointer;
    min-width: 134px;
    border: none;
    box-shadow: none;
    padding: ${(props) => props.theme.global.buttonPadding};
    font-weight: 400;
    line-height: 24px;
    font-size: ${(props) => props.theme.font.default};
    background: ${(props) => props.theme.colors.gradient};
    border-radius: ${(props) => props.theme.global.borderRadius};
    color: black;
    transition: all 0.15s ease;
    &.dimmedNormal {
        background: ${(props) => props.theme.colors.secondary};
    }
    &:hover {
        opacity: 0.8;
        box-shadow: 2px 2px 1px 0 rgba(0, 0, 0, 0.7);
    }

    &:disabled {
        background: lighten(${(props) => props.theme.colors.secondary}, 10%);
        cursor: not-allowed;
        color: rgba(106, 106, 106, 0.7);
        border-left: 1px solid rgba(106, 106, 106, 0.7);
        border-top: 1px solid rgba(106, 106, 106, 0.7);
        &:hover {
            transform: unset;
        }
    }
`

const DimmedBtn = styled.button`
    cursor: pointer;
    border: none;
    box-shadow: none;
    outline: none;
    background: transparent;
    border-radius: 0;
    color: ${(props) => props.theme.colors.secondary};
    font-size: ${(props) => props.theme.font.small};
    font-weight: 400;
    line-height: 24px;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    img {
        margin-right: 3px;
        &.rotate {
            transform: rotate(180deg);
            margin-right: 0;
            margin-left: 3px;
        }
    }
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.8;
    }
    &:disabled {
        cursor: not-allowed;
    }
`

const ArrowBtn = styled.button`
    span {
        background: ${(props) => props.theme.colors.gradient};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        color: ${(props) => props.theme.colors.primary};
        margin-right: 8px;
    }
    background: transparent;
    border: 0;

    cursor: pointer;
    box-shadow: none;
    outline: none;
    margin: 0;
    font-size: ${(props) => props.theme.font.small};
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.18px;
    display: flex;
    align-items: center;
    color: black;
    transition: all 0.3s ease;

    padding: 3px;
    // box-shadow: 1px 1px 1px 1px ${(props) => props.theme.colors.border};
    // border: 2px double ${(props) => props.theme.colors.border};
    // &:hover {
    //     transition: all 0.3s ease;
    //     background: ${(props) => props.theme.colors.border};
    //     box-shadow: 0 0 0 ${(props) => props.theme.colors.border};
    //     transform: translate(1px, 1px);
    // }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
        &:hover {
            opacity: 0.5;
        }
    }
    &:hover {
        opacity: 0.8;
    }
    img {
        position: relative;
        top: 0;
    }
`

const BorderedBtn = styled.button`
    background: ${(props) => props.theme.colors.gradient};
    padding: 2px;
    border-radius: 25px;
    box-shadow: none;
    outline: none;
    border: 0;
    cursor: pointer;
    &:disabled {
        cursor: not-allowed;
    }
`

const Inner = styled.div`
    background: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.inputBorderColor};
    border-radius: 25px;
    padding: 4px 6px;
    transition: all 0.3s ease;
    &:hover {
        opacity: 0.8;
        color: ${(props) => props.theme.colors.neutral};
    }
`
