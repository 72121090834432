import React from 'react'
import styled from 'styled-components'

interface Props {
    height?: number
    isLight?: boolean
}

const Brand = ({ height, isLight = true }: Props) => {
    return (
        <Container>
            <a
                href="https://www.h2odata.xyz"
                rel="noopener noreferrer"
                target="_blank"
            >
                <img
                    height={height}
                    src={require(`../assets/${
                        isLight ? 'brand' : 'brand'
                    }.svg`)}
                    alt="h2O"
                />
            </a>
        </Container>
    )
}

export default Brand

const Container = styled.div`
    a {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;
        color: white;
        font-size: 18px;
        letter-spacing: 2px;
        font-weight: bold;
        img {
            margin-left: 4px;
            padding: 4px;
            height: 30px;
        }
    }
`
