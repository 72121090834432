import React from 'react'

const Arrow = () => {
    return (
        <img
            src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTggMEw2LjU5IDEuNDFMMTIuMTcgN0gwVjlIMTIuMTdMNi41OSAxNC41OUw4IDE2TDE2IDhMOCAwWiIgZmlsbD0iI0ZBRkFGQSIgZmlsbC1vcGFjaXR5PSIwLjU0Ii8+Cjwvc3ZnPgo="
            alt=""
        />
    )
}

export default Arrow
