import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { formatNumber } from '../utils/helper'
import { COIN_TICKER } from '../utils/constants'
import { ReactComponent as Safe } from '../assets/safe-icon.svg'
import Button from './Button'
import EditIcon from './Icons/EditIcon'
import { useStoreActions, useStoreState } from '../store'

const SafeBlock = ({ ...props }) => {
    const { t } = useTranslation()
    const { popupsModel: popupsState } = useStoreState((state) => state)
    const collateral = formatNumber(props.collateral)
    const totalDebt = formatNumber(props.totalDebt)
    const [localSafeNames, setLocalSafeNames] = useState(
        JSON.parse(localStorage.getItem('safes') as any) ?? []
    )

    const createdAt = props.date
        ? dayjs.unix(props.date).format('MMM D, YYYY h:mm A')
        : null
    const { popupsModel: popupsActions } = useStoreActions((state) => state)
    useEffect(() => {
        setLocalSafeNames(
            JSON.parse(localStorage.getItem('safes') as any) ?? []
        )
    }, [popupsState.isEditSafeNameModalOpen])

    const returnState = (state: number) => {
        switch (state) {
            case 1:
                return 'Low'
            case 2:
                return 'Medium'
            case 3:
                return 'High'
            default:
                return ''
        }
    }
    return (
        <Container className={props.className}>
            <BlockContainer>
                <BlockHeader>
                    <SafeInfo>
                        <SafeIcon />

                        <SafeData>
                            <SafeTitle>
                                {localSafeNames.find(
                                    (x: { id: number }) => x.id === props.id
                                )
                                    ? localSafeNames.find(
                                          (x: { id: number }) =>
                                              x.id === props.id
                                      ).name
                                    : `Safe #${props.id}`}
                                <SafeEditName
                                    onClick={() => {
                                        popupsActions.setShowSideMenu(false)
                                        popupsActions.setIsEditSafeNameModalOpen(
                                            true
                                        )
                                        popupsActions.setModalData({
                                            name: localSafeNames.find(
                                                (x: { id: number }) =>
                                                    x.id === props.id
                                            )
                                                ? localSafeNames.find(
                                                      (x: { id: number }) =>
                                                          x.id === props.id
                                                  ).name
                                                : `Safe #${props.id}`,
                                            id: props.id,
                                        })
                                    }}
                                >
                                    <EditIcon></EditIcon>
                                </SafeEditName>
                            </SafeTitle>
                            {createdAt ? (
                                <Date>
                                    {t('created')} {createdAt}
                                </Date>
                            ) : null}
                            <SafeState
                                className={
                                    returnState(props.riskState)
                                        ? returnState(
                                              props.riskState
                                          ).toLowerCase()
                                        : 'dimmed'
                                }
                            >
                                {t('risk')}{' '}
                                <span>
                                    {returnState(props.riskState) || 'None'}
                                </span>
                            </SafeState>
                        </SafeData>
                    </SafeInfo>

                    <BtnContainer>
                        <Button>
                            <Link to={`/safes/${props.id}`}>
                                {t('manage_safe')}
                            </Link>
                        </Button>
                    </BtnContainer>
                </BlockHeader>
                <Block>
                    <Item>
                        <Label>{'OCEAN Deposited'}</Label>
                        <Value>{collateral}</Value>
                    </Item>
                    <Item>
                        <Label>{`${COIN_TICKER} Borrowed`}</Label>
                        <Value>{totalDebt}</Value>
                    </Item>
                    <Item>
                        <Label>{'Collateralization Ratio'}</Label>
                        <Value>{`${props.collateralRatio}%`}</Value>
                    </Item>
                    <Item>
                        <Label>{'Liquidation Price'}</Label>
                        <Value>${props.liquidationPrice}</Value>
                    </Item>
                </Block>
            </BlockContainer>
        </Container>
    )
}

export default SafeBlock

const Container = styled.div``

const BlockContainer = styled.div`
    padding: 20px;
    border: 1px dashed ${(props) => props.theme.colors.border};
    margin-bottom: 15px;
`

const BlockHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 400px) {
        flex-direction: column;
        gap: 10px;
    }
`

const SafeInfo = styled.div`
    display: flex;
    align-items: center;
`
const SafeEditName = styled.span`
    margin-left: 8px;
    cursor: pointer;
`

const SafeData = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    margin-left: 16px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-left: 10px;
  `}
`

const SafeTitle = styled.div`
    font-size: ${(props) => props.theme.font.large};
    color: ${(props) => props.theme.colors.primary};
    letter-spacing: -0.33px;
    line-height: 22px;
    font-weight: 600;
`

const Date = styled.div`
    font-size: ${(props) => props.theme.font.extraSmall};
    color: ${(props) => props.theme.colors.secondary};
    letter-spacing: -0.09px;
    line-height: 21px;
`

const SafeState = styled.div`
    color: ${(props) => props.theme.colors.successColor};
    border: 1px solid ${(props) => props.theme.colors.successColor};
    font-size: ${(props) => props.theme.font.default};
    border-radius: 5px;
    padding: 5px;
    width: fit-content;

    span {
        text-transform: capitalize;
    }

    &.dimmed {
        color: ${(props) => props.theme.colors.dimmedColor};
        border: 1px solid ${(props) => props.theme.colors.dimmedBorder};
    }
    &.medium {
        color: ${(props) => props.theme.colors.warningColor};
        border: 1px solid ${(props) => props.theme.colors.warningColor};
    }
    &.high {
        color: ${(props) => props.theme.colors.dangerColor};
        border: 1px solid ${(props) => props.theme.colors.dangerColor};
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        padding: 8px 10px;
        font-size: ${(props) => props.theme.font.extraSmall};
        text-align:center;
    `}
`

const Block = styled.div`
    margin-top: 10px;
    &:last-child {
        border-bottom: 0;
    }
`

const Item = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;

    &:last-child {
        margin-bottom: 0;
    }
`

const Label = styled.div`
    font-size: ${(props) => props.theme.font.default};
    color: ${(props) => props.theme.colors.secondary};
    letter-spacing: -0.09px;
    line-height: 21px;
`

const Value = styled.div`
    font-size: ${(props) => props.theme.font.default};
    color: ${(props) => props.theme.colors.secondary};
    letter-spacing: -0.09px;
    line-height: 21px;
`

const BtnContainer = styled.div`
    button {
        color: ${(props) => props.theme.colors.foreground};
        padding: 10px 20px;
    }

    a {
        color: ${(props) => props.theme.colors.foreground};
    }
`

const SafeIcon = styled(Safe)`
    width: 74px;
    height: 74px;
`
