import React from 'react'

const EditIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 490 491"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M472.469 81.443L408.869 17.843C395.769 1.44298 355.669 -12.357 325.469 17.843L34.5689 307.743C30.1689 312.143 28.0689 317.843 28.3689 323.343L1.26893 465.143C-2.93107 481.343 13.1689 491.743 24.1689 490.143L171.169 460.943C175.369 460.943 178.469 458.843 181.569 455.743L472.469 165.943C495.469 142.943 495.469 104.443 472.469 81.443ZM354.669 46.043C360.969 38.743 373.469 38.743 380.769 46.043L398.069 63.043L108.369 352.743L78.2689 322.343L354.669 46.043ZM61.7689 364.043L126.169 428.443L46.0689 444.243L61.7689 364.043ZM444.369 135.643L167.569 412.443L137.469 382.043L427.469 92.043L444.269 108.543C453.469 118.343 449.169 130.743 444.369 135.643Z"
                fill="white"
            />
        </svg>
    )
}

export default EditIcon
