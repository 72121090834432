import React from 'react'
import styled from 'styled-components'
interface Props {
    children: React.ReactNode
}

const GridContainer = ({ children }: Props) => {
    return (
        <Container>
            <InnerContent>{children}</InnerContent>
        </Container>
    )
}

export default GridContainer

const Container = styled.div`
    padding-bottom: 20px;
    @media (max-width: ${(props) => props.theme.global.gridMaxWidth}) {
        padding: 0 20px 20px 20px;
    }
`
const InnerContent = styled.div`
    max-width: ${(props) => props.theme.global.gridMaxWidth};
    width: 100%;
    background: ${(props) => props.theme.colors.background};
    padding: 32px;
    border-radius: 0;
    margin: 32px auto 0;
    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(-45deg, rgb(51, 238, 255), rgb(5, 18, 24)) 1;
`
