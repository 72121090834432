import React, { useRef, useEffect, useState, useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import useWindowSize from '../hooks/useWindowSize'
import { useStoreState } from '../store'
import { ISafeHistory } from '../utils/interfaces'
import dayjs from 'dayjs'
import { returnWalletAddress } from '../utils/helper'
import FeatherIconWrapper, { IconName } from './FeatherIconWrapper'
import SafeIcon from './Icons/SafeIcon'
import {
    ExternalLink as ExternalLinkIcon,
    ArrowLeft,
    ArrowRight,
} from 'react-feather'

interface Props {
    hideHistory?: boolean
}
const SafeHistory = ({ hideHistory }: Props) => {
    const { t } = useTranslation()
    const [page, setPage] = useState(0)
    const [perPage] = useState(5)
    const [total, setTotal] = useState(0)
    const [colWidth, setColWidth] = useState('100%')
    const { width } = useWindowSize()
    const ref = useRef<HTMLDivElement>(null)
    const { safeModel: safeState } = useStoreState((state) => state)

    const returnIcon = (color: string, icon: IconName) => {
        // Hardcoding to white color
        if (color) {
            return <FeatherIconWrapper name={icon} color="white" />
        }
        return <SafeIcon />
    }
    const formatRow = (item: ISafeHistory, i: number) => {
        const { title, date, amount, link, txHash, icon, color } = item
        const humanizedAmount =
            amount.toString().length < 4 ? amount : amount.toFixed(4)
        const humanizedDate = dayjs
            .unix(Number(date))
            .format('MMM D, YYYY h:mm A')

        return (
            <Row ref={ref} key={title + i}>
                <Col>
                    {returnIcon(color, icon)}
                    {title}
                </Col>
                <Col>{humanizedDate}</Col>
                <Col>{humanizedAmount}</Col>
                <Col>
                    <ExternalLink href={link} target="_blank">
                        {returnWalletAddress(txHash)}{' '}
                        <ExternalLinkIcon color="currentColor" />
                    </ExternalLink>
                </Col>
            </Row>
        )
    }

    const handlePageClick = ({ selected }: any) => {
        setPage(selected)
    }

    useEffect(() => {
        if (ref && ref.current) {
            setColWidth(String(ref.current.clientWidth) + 'px')
        }
    }, [ref, width])

    const setPagination = (history: Array<ISafeHistory>) => {
        if (!history.length) return
        setTotal(Math.ceil(history.length / perPage))
    }

    const setPaginationCB = useCallback(setPagination, [perPage])

    useEffect(() => {
        setPaginationCB(safeState.historyList)
    }, [setPaginationCB, safeState.historyList])

    return (
        <div>
            <Title>
                {t('history')}{' '}
                {safeState.historyList.length
                    ? `- (${safeState.historyList.length})`
                    : null}
            </Title>

            <Container>
                {!safeState.historyList.length || hideHistory ? null : (
                    <Header style={{ width: colWidth }}>
                        <Thead>Action</Thead>
                        <Thead>Date</Thead>
                        <Thead>Amount</Thead>
                        <Thead>Receipt</Thead>
                    </Header>
                )}

                {!hideHistory || safeState.historyList.length > 0 ? (
                    <>
                        <List>
                            {safeState.historyList
                                .slice(page * perPage, (page + 1) * perPage)
                                .map((item: ISafeHistory, i: number) =>
                                    formatRow(item, i)
                                )}
                        </List>
                    </>
                ) : (
                    <HideHistory>{t('no_history')}</HideHistory>
                )}
            </Container>

            {safeState.historyList.length > perPage ? (
                <PaginationContainer>
                    <ReactPaginate
                        previousLabel={<ArrowLeft />}
                        nextLabel={<ArrowRight />}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={4}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </PaginationContainer>
            ) : null}
        </div>
    )
}

export default SafeHistory

const Container = styled.div`
    background: ${(props) => props.theme.colors.background};
    border: 1px solid ${(props) => props.theme.colors.border};
`

const Title = styled.div`
    color: ${(props) => props.theme.colors.secondary};
    font-size: ${(props) => props.theme.font.large};
    line-height: 25px;
    letter-spacing: -0.47px;
    font-weight: 600;
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
    padding: 15px 0;
`

const List = styled.div``

const Header = styled.div`
    display: flex;
    padding: 12px 30px 12px 20px;
`
const Thead = styled.div`
    flex: 0 0 20%;
    font-size: ${(props) => props.theme.font.default};
    color: ${(props) => props.theme.colors.secondary};
    letter-spacing: 0.01px;
    font-weight: normal;

    &:first-child {
        flex: 0 0 30%;
        text-align: left;
    }

    &:nth-child(2) {
        flex: 0 0 30%;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        &:nth-child(2),
        &:nth-child(3) {
            display: none;
        }
        flex: 0 0 50%;
        &:nth-child(1) {
            flex: 0 0 50%;
        }
  `}
`

const Row = styled.div`
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border-top: 1px solid ${(props) => props.theme.colors.border};
`

const Col = styled.div`
    flex: 0 0 20%;
    display: flex;
    align-items: center;
    letter-spacing: -0.09px;
    color: ${(props) => props.theme.colors.primary};
    font-size: ${(props) => props.theme.font.default};

    &:first-child {
        flex: 0 0 30%;
        justify-content: flex-start;
    }

    &:nth-child(2) {
        flex: 0 0 30%;
    }

    &:last-child {
        font-weight: 600;
    }

    svg {
        margin-right: 11px;
        color: gray;
        width: 23px;
        height: 23px;
        &.gray {
            color: gray;
        }
        &.green {
            color: #4ac6b2;
        }
        &.red {
            color: red;
        }
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        &:nth-child(2),
        &:nth-child(3) {
        display: none;
        }
        flex: 0 0 50%;
        &:nth-child(1) {
        flex: 0 0 50%;
        }
    `}

    ${({ theme }) => theme.mediaWidth.upToSmall`
      font-size:${(props) => props.theme.font.extraSmall};
    `}
`

const ExternalLink = styled.a`
    background: ${(props) => props.theme.colors.gradient};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: ${(props) => props.theme.colors.inputBorderColor};

    svg {
        width: 16px;
        height: 16px;
        stroke: ${(props) => props.theme.colors.gradient};
    }
`

const HideHistory = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    font-size: ${(props) => props.theme.font.small};
`

const PaginationContainer = styled.div`
    text-align: center;
    margin-top: 0.5rem;
    padding-right: 0.7rem;

    .pagination {
        .previous,
        .next {
            background-color: ${(props) => props.theme.colors.gradient};
            width: 30px;
            height: 30px;
            border-radius: 5px;

            svg {
                stroke: ${(props) => props.theme.colors.foreground};
            }
        }
    }

    .pagination {
        li {
            display: inline-block;
            vertical-align: middle;
            cursor: pointer;
            text-align: center;
            outline: none;
            box-shadow: none;
            margin: 0 2px;
            font-size: ${(props) => props.theme.font.default};

            &.active {
                border-radius: 2px;
                color: ${(props) => props.theme.colors.secondary};
            }

            a {
                justify-content: center;
                display: flex;
                align-items: center;
                height: 20px;
                width: 20px;
                outline: none;
                box-shadow: none;

                &:hover {
                    background: rgba(0, 0, 0, 0.08);
                }
            }

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                margin-left: 10px;
            }

            &:first-child,
            &:last-child {
                padding: 0;
                a {
                    height: auto;
                    width: auto;
                    padding: 3px 8px;
                    border-radius: 2px;
                    &:hover {
                        background: rgba(0, 0, 0, 0.08);
                    }
                    text-align: center;
                }

                &.active {
                    a {
                        color: ${(props) => props.theme.colors.secondary};
                    }
                }

                &.disabled {
                    pointer-events: none;
                    opacity: 0.2;
                }
            }
        }
    }
`
