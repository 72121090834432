import React from 'react'
import SafeBody from './SafeBody'
import SafeContent from './SafeContent'

const Safe = () => {
    return (
        <SafeContent>
            <SafeBody />
        </SafeContent>
    )
}

export default Safe
