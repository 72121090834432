import { ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'
import {
    MERKLE_DISTRIBUTOR,
    MERKLE_DISTRIBUTOR_V2,
} from '../constants/contracts'
import { useActiveWeb3React } from '../hooks'
import useAirdrop from '../hooks/useAirdrop'
import useDebounce from '../hooks/useDebounce'
import store, { useStoreState } from '../store'
import { geb } from '../utils/constants'
import merkleTree from '../constants/merkleTree.json'
import merkleTreev2 from '../constants/merkleTreev2.json'

export default function ApplicationUpdater(): null {
    const { library, chainId, account } = useActiveWeb3React()
    const { connectWalletModel: connectedWalletState } = useStoreState(
        (state) => state
    )
    const { blockNumber } = connectedWalletState

    const [state, setState] = useState<{
        chainId: number | undefined
        balance: string
    }>({
        chainId,
        balance: '0',
    })
    const {
        isClaimed: airdropClaimed,
        isEligible: airdropEligible,
        amount: airdropAmount,
    } = useAirdrop(MERKLE_DISTRIBUTOR, merkleTree)
    const {
        isClaimed: airdropClaimed2,
        isEligible: airdropEligible2,
        amount: airdropAmount2,
    } = useAirdrop(MERKLE_DISTRIBUTOR_V2, merkleTreev2)

    const fetchOceanBalanceCallBack = useCallback(
        (result: any) => {
            setState((state) => {
                if (chainId === state.chainId) {
                    return {
                        chainId,
                        balance: ethers.utils.formatEther(result),
                    }
                }
                return state
            })
        },
        [chainId, setState]
    )

    // attach/detach listeners
    useEffect(() => {
        if (!library || !chainId || !account) return undefined
        setState({ chainId, balance: '0' })
        const collateralToken = geb.getErc20Contract(
            geb.contracts.ocean.address
        )
        collateralToken
            .balanceOf(account as string)
            .then(fetchOceanBalanceCallBack)
            .catch((error) =>
                console.error(
                    `Failed to fetch balance for chainId: ${chainId}`,
                    error
                )
            )
    }, [chainId, library, fetchOceanBalanceCallBack, account, blockNumber])

    const debouncedState = useDebounce(state, 100)

    useEffect(() => {
        if (!debouncedState.chainId || !debouncedState.balance) return
        store.dispatch.connectWalletModel.updateOceanBalance({
            chainId: debouncedState.chainId,
            balance: debouncedState.balance,
        })

        store.dispatch.connectWalletModel.fetchFiatPrice()
    }, [debouncedState.balance, debouncedState.chainId])

    useEffect(() => {
        if (
            (!airdropClaimed && airdropEligible) ||
            (!airdropClaimed2 && airdropEligible2)
        ) {
            store.dispatch.connectWalletModel.setClaimbleProt(
                airdropAmount + airdropAmount2
            )
        } else {
            store.dispatch.connectWalletModel.setClaimbleProt('0')
        }
    }, [
        airdropAmount,
        airdropClaimed,
        airdropEligible,
        airdropAmount2,
        airdropClaimed2,
        airdropEligible2,
    ])

    return null
}
