import React from 'react'

const SafeIcon = ({ ...rest }) => {
    return (
        <svg
            {...rest}
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="1.29999"
                y="0.800049"
                width="17.4"
                height="17.4"
                stroke="white"
            />
            <rect
                x="3.3125"
                y="2.6687"
                width="13.375"
                height="13.375"
                stroke="white"
            />
            <rect
                x="0.799988"
                y="4.19019"
                width="2.3"
                height="2.3"
                fill="white"
            />
            <rect
                x="0.799988"
                y="12.2224"
                width="2.3"
                height="2.3"
                fill="white"
            />
            <circle cx="9.99998" cy="9.49995" r="3.2375" stroke="white" />
            <circle cx="9.99998" cy="9.3563" r="0.65" stroke="white" />
            <rect
                x="9.42499"
                y="6.53979"
                width="1.15"
                height="2.0132"
                fill="white"
            />
            <rect
                x="9.42499"
                y="3.6062"
                width="1.15"
                height="1.24583"
                fill="white"
            />
            <rect
                x="8.5625"
                y="13.9084"
                width="2.875"
                height="0.790625"
                fill="white"
            />
            <rect
                x="7.68314"
                y="11.3579"
                width="1.15"
                height="2.0113"
                transform="rotate(-120 7.68314 11.3579)"
                fill="white"
            />
            <rect
                width="1.15"
                height="2.01174"
                transform="matrix(0.5 -0.866026 -0.866025 -0.5 12.3172 11.3579)"
                fill="white"
            />
        </svg>
    )
}

export default SafeIcon
