import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useStoreState } from '../../store'

interface Props {
    width?: string
    maxWidth?: string
    children: React.ReactNode
}

const SafeContent = ({ width, maxWidth, children }: Props) => {
    const { t } = useTranslation()
    const { popupsModel: popupsState } = useStoreState((state) => state)

    const { type, isCreate } = popupsState.safeOperationPayload
    return (
        <ModalContent
            style={{
                width: width || '100%',
                maxWidth: maxWidth || '720px',
            }}
        >
            <Header id="safe-header">
                {isCreate ? t('create_safe') : `Safe ${t(type)}`}
            </Header>
            {children}
        </ModalContent>
    )
}

export default SafeContent

const ModalContent = styled.div`
    background: ${(props) => props.theme.colors.background};
`

const Header = styled.div`
    padding: 25px 40px;
    font-size: ${(props) => props.theme.font.large};
    color: ${(props) => props.theme.colors.primary};
    letter-spacing: -0.47px;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height 1px;
        background: linear-gradient(90deg, rgba(50, 163, 220, 0) 0%, #32A3DC 49.48%, rgba(50, 163, 220, 0) 100%);
    }

    @media (max-width: 768px) {
        padding: 20px;
    }
`
