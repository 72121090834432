import { mediaWidthTemplates } from '../constants'
import { Theme } from '../interfaces'

const darkTheme: Theme = {
    colors: {
        primary: '#32A3DC',
        secondary: '#fff',
        gradient: '#33EEFF',
        neutral: '#051218',
        background: '#051218',
        backgroundSecondary: '#000080',
        overlay: 'rgba(0, 0, 0, 0.8)',
        border: '#33EEFF',
        foreground: '#000',
        dangerColor: '#E52A78',
        dangerBackground: '#F8D7DA',
        dangerBorder: '#F5C6CB',
        alertColor: '#004085',
        alertBackground: '#CCE5FF',
        alertBorder: '#B8DAFF',
        successColor: '#98E55C',
        successBackground: '#D4EDDA',
        successBorder: '#C3E6CB',
        warningColor: '#FE9505',
        warningBackground: '#FFF3CD',
        warningBorder: '#856404',
        dimmedColor: '#ffffff',
        dimmedBackground: '#A4ABB7',
        dimmedBorder: '#878787',
        placeholder: '#212429',
        inputBorderColor: '#6fbcdb',
        boxShadow: '#323232',
    },
    font: {
        extraSmall: '12px',
        small: '14px',
        default: '18px',
        medium: '20px',
        large: '24px',
        extraLarge: '32px',
    },
    global: {
        gridMaxWidth: '900px',
        borderRadius: '5px',
        extraCurvedRadius: '20px',
        buttonPadding: '14px 24px',
        modalWidth: '720px',
    },
    mediaWidth: mediaWidthTemplates,
}

export { darkTheme }
