import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useStoreActions } from '../store'
import { SHOW_AUCTIONS } from '../utils/constants'

const NavLinks = () => {
    const { t } = useTranslation()
    const { popupsModel: popupsActions } = useStoreActions((state) => state)

    const handleLinkClick = (
        e: React.MouseEvent<HTMLElement>,
        disable = false,
        externalLink = ''
    ) => {
        if (disable) {
            e.preventDefault()
        }
        popupsActions.setShowSideMenu(false)
        if (externalLink) {
            window.open(externalLink, '_blank')
            e.preventDefault()
        }
    }

    return (
        <Nav>
            <NavBarLink
                id="app-link"
                to="/"
                onClick={(e) => handleLinkClick(e, false)}
            >
                {t('home')}
            </NavBarLink>
            {SHOW_AUCTIONS && SHOW_AUCTIONS !== '1' ? null : (
                <NavBarLink
                    to="/auctions"
                    onClick={(e) => handleLinkClick(e, false)}
                >
                    {t('auctions')}
                </NavBarLink>
            )}
            {/* 
            <NavBarLink
                to="/earn/staking"
                onClick={(e) => handleLinkClick(e, false)}
            >
                {t('staking')}
            </NavBarLink> */}

            {/* <Box className="has-menu">
                <LinkItem>{t('earn')}</LinkItem>
                <MenuBox className="menu-box">
                    <MenuContent className="menu-content">
                        <IntLink to="/earn/staking">Staking </IntLink>
                    </MenuContent>
                </MenuBox>
            </Box> */}
            <NavBarLink
                to="/"
                onClick={(e) =>
                    handleLinkClick(
                        e,
                        false,
                        'https://liquid-staking.h2odata.xyz/'
                    )
                }
            >
                {t('liquid_staking')}
            </NavBarLink>
            {/* <NavBarLink
                to="/"
                onClick={(e) =>
                    handleLinkClick(
                        e,
                        false,
                        NETWORK_ID === 1
                            ? REACT_APP_STATS_APP_URL
                            : 'https://master--sharp-hermann-c548d0.netlify.app/'
                    )
                }
            >
                {t('analytics')}
            </NavBarLink> */}

            {/* <NavBarLink to="/news" onClick={(e) => handleLinkClick(e, false)}>
                {t('news')}
            </NavBarLink> */}
            <NavBarLink
                to="/airdrop"
                onClick={(e) => handleLinkClick(e, false)}
            >
                {t('airdrop')}
            </NavBarLink>

            {/* <SepBlock className="disableDesktop">
        <NavBarLink to="" onClick={(e) => handleLinkClick(e, false)}>
          {t('request_features')}
        </NavBarLink>

        <NavBarBtn
          onClick={() => {
            popupsActions.setShowSideMenu(false);
            popupsActions.setIsSettingModalOpen(true);
          }}
        >
          {t('settings')}
        </NavBarBtn>

        <NavBarLink to="" onClick={(e) => handleLinkClick(e, true)}>
          {t('talk_to_us')}
        </NavBarLink>
      </SepBlock> */}
        </Nav>
    )
}

export default NavLinks

const Nav = styled.div`
    display: flex;
    align-items: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
  
    flex-direction: column;
    
  `}
`

const NavBarLink = styled(NavLink)`
    color: white;
    font-weight: 400;
    transition: all 0.3s ease;
    letter-spacing: 1px;
    font-size: ${(props) => props.theme.font.medium};
    text-transform: uppercase;

    &:hover {
        background-clip: text;
        color: ${(props) => props.theme.colors.inputBorderColor};
    }

    svg {
        display: none;
        &.fill {
            fill: ${(props) => props.theme.colors.secondary};
            color: ${(props) => props.theme.colors.secondary};
        }
        &.opacity {
            opacity: 0.5;
        }
        ${({ theme }) => theme.mediaWidth.upToSmall`
   width: 18px;
         height: 18px;
         display: inline !important;
         margin-right:10px;
         color: ${(props) => props.theme.colors.secondary}
        `}
    }

    margin-right: 20px;
    &:last-child {
        margin-right: 0;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
        border: 1px solid ${(props) => props.theme.colors.primary};
        color: ${(props) => props.theme.colors.secondary};
        border-radius: 5px;
        margin: 5px 0;
        width: 100%;
        padding: 20px 15px;
  `}
`

// const Box = styled.div`
//     position: relative;
//     cursor: pointer;
//     &:hover {
//         .menu-box {
//             display: block;
//         }
//     }

//     svg {
//         display: none;
//     }

//     ${({ theme }) => theme.mediaWidth.upToSmall`
//       flex: 0 0 100%;
//       min-width: 100%;
//       font-weight: normal;
//       svg {
//         width: 18px;
//          height: 18px;
//          display: inline !important;
//          margin-right:10px;
//          color: ${(props) => props.theme.colors.secondary}
//     }
//     `}
// `

// const LinkItem = styled.div`
//     color: white;
//     font-weight: 400;
//     transition: all 0.3s ease;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
//     font-size: 18px;
//     padding: 5px 0;
//     margin-right: 20px;
//     letter-spacing: 1px;
//     text-transform: uppercase;

//     &:hover {
//         background-clip: text;
//         color: ${(props) => props.theme.colors.secondary};
//         svg {
//             color: ${(props) => props.theme.colors.secondary};
//         }
//     }
//     ${({ theme }) => theme.mediaWidth.upToSmall`
//         border: 1px solid ${(props) => props.theme.colors.primary};
//         color: ${(props) => props.theme.colors.secondary};
//         border-radius: 5px;
//         margin: 5px 0;
//         width: 100%;
//         padding: 20px 15px;

//         font-weight:normal;
//     `}
// `

// const MenuBox = styled.div`
//     display: none;
//     position: absolute;
//     top: 28px;
//     z-index: 99;
//     background: ${(props) => props.theme.colors.background};
//     padding: 10px;
//     min-width: 200px;
//     box-shadow: 4px 4px 10px 0 rgba(0, 0, 0, 0.9);

//     ${({ theme }) => theme.mediaWidth.upToSmall`
//         display:block;
//         position:static;
//         box-shadow: none;
//         padding:0;
//         padding-left:28px;
//     `};
// `
// const MenuContent = styled.div``

// const ExtLink = styled.a`
//     color: ${(props) => props.theme.colors.secondary};
//     font-size: ${(props) => props.theme.font.default};
//     font-weight: 400;
//     line-height: 24px;
//     letter-spacing: 1px;
//     transition: all 0.3s ease;
//     display: block;
//     margin: 10px 0;
//     padding: 0 5px;
//     cursor: pointer;
//     &:last-child {
//         margin-bottom: 0;
//     }

//     &:hover {
//         text-decoration: none;
//         color: ${(props) => props.theme.colors.primary};
//     }

//     img {
//         display: none;
//     }
//     ${({ theme }) => theme.mediaWidth.upToSmall`
//         color: ${(props) => props.theme.colors.secondary};
//         transform: translateX(0px) !important;
//         border-bottom: 1px solid ${(props) => props.theme.colors.primary};
//         padding: 15px 0;
//         margin: 0;

//         &:last-child {
//             border-bottom: 0;
//         }
//     `}
// `

// const IntLink = styled(NavLink)`
//     color: ${(props) => props.theme.colors.secondary};
//     font-size: ${(props) => props.theme.font.default};
//     font-weight: 400;
//     line-height: 24px;
//     letter-spacing: 1px;
//     transition: all 0.3s ease;
//     display: block;
//     margin: 10px 0;
//     padding: 0 5px;
//     cursor: pointer;
//     &:hover {
//         text-decoration: none;
//         color: ${(props) => props.theme.colors.primary};
//     }

//     img {
//         display: none;
//     }
//     ${({ theme }) => theme.mediaWidth.upToSmall`
//         color: ${(props) => props.theme.colors.secondary};
//         transform: translateX(0px) !important;
//         border-bottom: 1px solid ${(props) => props.theme.colors.primary};
//         padding: 15px 0;
//         margin: 0;

//         &:last-child {
//             border-bottom: 0;
//         }
//     `}
// `

// const NavBarBtn = styled.div`
//     background: none;
//     box-shadow: none;
//     font-weight: 600;
//     outline: none;
//     cursor: pointer;

//     border: 0;
//     color: ${(props) => props.theme.colors.secondary};
//     padding: 9px 10px;
//     margin: 0;
//     line-height: normal;
//     text-align: left;
//     transition: all 0.3s ease;
//     &:hover {
//         background: ${(props) => props.theme.colors.gradient};
//         background-clip: text;
//         -webkit-background-clip: text;
//         -webkit-text-fill-color: transparent;
//         color: ${(props) => props.theme.colors.inputBorderColor};
//     }
// `

// const SepBlock = styled.div`
//     border-top: 1px solid ${(props) => props.theme.colors.border};
//     display: flex;
//     flex-direction: column;
//     padding: 10px 0 0 !important;
//     margin-top: 15px !important;
//     > div,
//     a {
//         padding-top: 10px;
//         padding-bottom: 10px;
//         color: ${(props) => props.theme.colors.secondary} !important;
//     }

//     @media (min-width: 768px) {
//         &.disableDesktop {
//             display: none;
//         }
//     }
// `
