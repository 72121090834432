/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { useActiveWeb3React } from '../hooks'

const epns = require('@epnsproject/frontend-sdk')
// const epns = require('@epnsproject/frontend-sdk-staging')
const EPNS_ADDRESS = '0x284D1B309aea124fE8a28Dc362EaD0eF487A0749'

const ButtonEPNS = () => {
    console.log(epns)
    const { library, account } = useActiveWeb3React()
    if (!library || !account) throw new Error('No library or account')

    const signer = library.getSigner(account)
    const [state, setState] = useState({
        isSubscribed: false,
    })

    useEffect(() => {
        async function isSubscribed() {
            const isSubscribed = await epns.channels.isUserSubscribed(
                account,
                EPNS_ADDRESS
            )
            setIsSubscribed(isSubscribed)
        }
        isSubscribed()
    })

    const setIsSubscribed = (value: boolean) => {
        setState({ isSubscribed: value })
    }

    const handleConnect = () => {
        epns.channels.optIn(signer, EPNS_ADDRESS, 1, account, {
            onSuccess: () => {
                setState({ isSubscribed: true })
            },
        })
    }
    const handleDisconnect = () => {
        epns.channels.optOut(signer, EPNS_ADDRESS, 1, account, {
            onSuccess: () => {
                setState({ isSubscribed: false })
            },
        })
    }

    return (
        <>
            <DataContainer className={state.isSubscribed ? 'connected' : ''}>
                {state.isSubscribed ? (
                    <Left className="connected">
                        <header>H2O notification service</header>
                    </Left>
                ) : (
                    <Left>
                        <header>
                            Stay safe!{' '}
                            <img src={require('../assets/lock.png')} />
                        </header>
                        <p>
                            Enable push notifications to know when your SAFE is
                            at risk.
                        </p>
                        <section>
                            <a
                                href="https://chrome.google.com/webstore/detail/epns-protocol-alpha/lbdcbpaldalgiieffakjhiccoeebchmg/related"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <img
                                    src={require('../assets/chromewebstore.png')}
                                />
                            </a>
                            <a
                                href="https://apps.apple.com/us/app/ethereum-push-service-epns/id1528614910"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <img src={require('../assets/appstore.png')} />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=io.epns.epns&hl=en_ZA"
                                target={'_blank'}
                                rel="noreferrer"
                            >
                                <img
                                    src={require('../assets/googleplay.png')}
                                />
                            </a>
                        </section>
                    </Left>
                )}

                <Right>
                    <Button
                        onClick={
                            !state.isSubscribed
                                ? handleConnect
                                : handleDisconnect
                        }
                        className={state.isSubscribed ? 'connected' : ''}
                    >
                        {state.isSubscribed ? 'Disconnect' : 'Connect'}
                    </Button>
                </Right>
            </DataContainer>
        </>
    )
}

export default ButtonEPNS

const Left = styled.div`
    display: flex;
    flex-direction: column;
    margin: 8px 0;
    align-self: center;
    color: ${(props) => props.theme.colors.secondary};

    header {
        font-size: 20px;
        font-weight: 600;
        img {
            position: relative;
            top: 2px;
            width: 24px;
        }
    }
    p {
        margin-top: 8px;
        margin-bottom: 0;
    }

    a {
        overflow: hidden;
        cursor: pointer;
    }
    img {
        width: 28px;
        margin-right: 8px;
    }
    section {
        display: flex;
        align-items: center;
        margin-top: 16px;
        flex-wrap: wrap;
    }
`
const Right = styled.div`
    display: flex;
    margin: 10px 0;
    align-items: center;
    color: ${(props) => props.theme.colors.primary};
    Button {
        transform: scale(0.8);
        background: rgb(226, 8, 128);
        color: ${(props) => props.theme.colors.secondary};
        font-weight: 600;
        &.connected {
            background: green;
        }
    }
`

const DataContainer = styled.div`
    padding: 24px;
    display: flex;
    background: url('https://cdn-images-1.medium.com/max/2000/1*IDYplkz5k9ubEwb6ANq_-A.png');
    border-radius: 8px;
    margin-bottom: 32px;
    justify-content: space-between;
    &.connected {
        padding: 0 20px;
    }
    @media (max-width: 991px) {
        flex-direction: column;
    }
`
