import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Button from './Button'

interface Props {
    hideFAQ: () => void
    type: 'DEBT' | 'SURPLUS' | 'STAKED_TOKEN'
}

interface FAQ {
    title: string
    desc: string
}
interface FAQS {
    debt: Array<FAQ>
    surplus: Array<FAQ>
    staked_token: Array<FAQ>
}

const AuctionsFAQ = ({ hideFAQ, type }: Props) => {
    const { t } = useTranslation()

    const faqs: FAQS = {
        debt: [
            {
                title: t('debt_auction_minting_flx_header'),
                desc: t('debt_auction_minting_flx_desc'),
            },
            {
                title: t('debt_auction_how_to_bid'),
                desc: t('debt_auction_how_to_bid_desc'),
            },
            {
                title: t('debt_auction_claim_tokens'),
                desc: t('debt_auction_claim_tokens_desc'),
            },
        ],
        surplus: [
            {
                title: t('surplus_auction_minting_flx_header'),
                desc: t('surplus_auction_minting_flx_desc'),
            },
            {
                title: t('surplus_auction_how_to_bid'),
                desc: t('surplus_auction_how_to_bid_desc'),
            },
            {
                title: t('surplus_auction_claim_tokens'),
                desc: t('surplus_auction_claim_tokens_desc'),
            },
        ],
        staked_token: [
            {
                title: t('staked_auction_minting_flx_header'),
                desc: t('staked_auction_minting_flx_desc'),
            },
            {
                title: t('staked_auction_how_to_bid'),
                desc: t('staked_auction_how_to_bid_desc'),
            },
            {
                title: t('staked_auction_claim_tokens'),
                desc: t('staked_auction_claim_tokens_desc'),
            },
        ],
    }

    return (
        <HeroSection>
            <Header>
                How do {type === 'STAKED_TOKEN' ? '' : 'H2O'}{' '}
                {type === 'STAKED_TOKEN' ? 'staked token' : type.toLowerCase()}{' '}
                auctions work? <Button text={t('hide_faq')} onClick={hideFAQ} />
            </Header>
            <Content>
                {faqs[
                    type.toLowerCase() as 'debt' | 'surplus' | 'staked_token'
                ].map((faq: FAQ) => (
                    <Col key={faq.title}>
                        <InnerCol>
                            <SectionHeading>{faq.title}</SectionHeading>
                            <SectionContent>{faq.desc}</SectionContent>
                        </InnerCol>
                    </Col>
                ))}
            </Content>
        </HeroSection>
    )
}

export default AuctionsFAQ

const HeroSection = styled.div`
    margin-bottom: 20px;
    margin-top: 30px;
    overflow: hidden;
`
const Header = styled.div`
    font-size: ${(props) => props.theme.font.medium};
    display: flex;
    align-items: center;
    font-weight: 800;
    justify-content: space-between;
    margin-bottom: 8px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary};
    button {
        margin-left: 10px;
        font-size: ${(props) => props.theme.font.small};
        min-width: auto !important;
        border-radius: 4px;
        padding: 2px 10px;
        background: ${(props) => props.theme.colors.gradient};
    }

    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    flex-direction:column;
    margin-bottom:25px;
    button {
      margin-top:10px;
    }
  `}
`
const Content = styled.div`
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    gap: 8px;
    margin: 0 -10px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction:column;
  `}
`
const SectionHeading = styled.div`
    text-align: left;
    font-size: ${(props) => props.theme.font.default};
    font-weight: 600;
`
const SectionContent = styled.div`
    margin-top: 10px;
    font-size: ${(props) => props.theme.font.small};
    line-height: 23px;
    color: ${(props) => props.theme.colors.secondary};
    text-align: left;
`

const Col = styled.div`
    flex: 0 0 33.3%;
    padding: 0 10px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex: 0 0 100%;
    margin-top:20px;
  `}
`

const InnerCol = styled.div`
    border: 1px dashed ${(props) => props.theme.colors.border};
    background: ${(props) => props.theme.colors.background};
    border-radius: ${(props) => props.theme.global.borderRadius};
    height: 100%;
    padding: 20px;
    text-align: center;
    img {
        width: 40px;
        margin-bottom: 20px;
    }
`
