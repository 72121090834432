import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import { NETWORK_ID } from '../connectors'
import MerkleDistributorAbi from '../constants/abis/MerkleDistributor.json'
import { useStoreActions } from '../store'
import useGeb from './useGeb'

interface AirdropInfo {
    index: number
    amount: string
    proof: string[]
}

const useAirdrop = (merkleDistributorAddress: string, merkleTree: any) => {
    const { account, library } = useWeb3React()
    const geb = useGeb()
    const { connectWalletModel: connectWalletActions } = useStoreActions(
        (state) => state
    )

    const [airdropInstance, setAirdropInstance] =
        useState<ethers.Contract | null>(null)

    const [isClaimed, setIsClaimed] = useState(false)
    const [isEligible, setIsEligible] = useState(false)
    const [amount, setAmount] = useState('0')
    const [airdropInfo, setAirdropInfo] = useState<null | AirdropInfo>(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (library && merkleDistributorAddress) {
            const distributorInstance = new ethers.Contract(
                merkleDistributorAddress,
                MerkleDistributorAbi,
                library.getSigner()
            )

            setAirdropInstance(distributorInstance)
        }
    }, [library, merkleDistributorAddress])

    const getIsClaimed = async (index: Number) => {
        if (airdropInstance) {
            const claimed = await airdropInstance.isClaimed(index)

            setIsClaimed(claimed)
        }
    }

    const getAmount = async (hexAmount: string) => {
        const decAmount = ethers.BigNumber.from(hexAmount)
        // Token is 18 decimals. If not, change the value below
        const formattedAmount = ethers.utils.formatUnits(decAmount, 18)

        setAmount(formattedAmount)
    }

    useEffect(() => {
        if (airdropInstance && account) {
            const merkleTreeClaims: any = merkleTree.claims

            const isFound = Object.keys(merkleTreeClaims).find(
                (claim) => claim.toLowerCase() === account.toLowerCase()
            )

            if (isFound) {
                setIsEligible(true)

                const airdropDetails = merkleTreeClaims[account]

                setAirdropInfo(airdropDetails)
                getIsClaimed(airdropDetails.index)
                getAmount(airdropDetails.amount)
            }
        }

        // eslint-disable-next-line
    }, [airdropInstance, account])

    const updateState = async () => {
        if (airdropInfo) {
            await getIsClaimed(airdropInfo?.index)
        }
    }

    const claim = async () => {
        if (airdropInstance && airdropInfo) {
            setLoading(true)

            try {
                const index = airdropInfo.index
                const amount = ethers.BigNumber.from(airdropInfo.amount)
                const proof = airdropInfo.proof

                const tx = await airdropInstance?.claim(
                    index,
                    account,
                    amount,
                    proof,
                    { from: account }
                )
                const receipt = await tx.wait()

                if (receipt.status === 1) {
                    console.log('success')
                }
            } catch (err) {
                console.log('failed', err)
            } finally {
                const govTokenBalance =
                    await geb.contracts.protocolToken.balanceOf(account!)

                connectWalletActions.updateProtBalance({
                    chainId: NETWORK_ID,
                    balance: ethers.utils.formatEther(govTokenBalance),
                })

                await updateState()
                setLoading(false)

                window.location.reload()
            }
        }
    }

    return {
        isClaimed,
        isEligible,
        amount,
        loading,
        claim,
    }
}

export default useAirdrop
