/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import PSDNLogoSmall from '../components/Icons/PSDNLogoSmall'
import Loader from '../components/Loader'
import {
    MERKLE_DISTRIBUTOR,
    MERKLE_DISTRIBUTOR_V2,
} from '../constants/contracts'
import useAirdrop from '../hooks/useAirdrop'
import merkleTree from '../constants/merkleTree.json'
import merkleTreev2 from '../constants/merkleTreev2.json'

const merkleTrees = [merkleTree, merkleTreev2]

const Airdrop = () => {
    const Airdrops = [MERKLE_DISTRIBUTOR, MERKLE_DISTRIBUTOR_V2].map(
        (address, index) => {
            const { isClaimed, isEligible, amount, loading, claim } =
                useAirdrop(address, merkleTrees[index])
            return { isClaimed, isEligible, amount, loading, claim }
        }
    )
    const [activeAirdropIndex, setActiveAirdropIndex] = useState(0)

    return (
        <Container>
            {Airdrops.find((x) => x.isEligible) ? (
                <>
                    <Box>
                        <AidropDetails>
                            <header>
                                <Title>Airdrop </Title>
                                <Text>
                                    Your airdrop is here! Click the “claim”
                                    button to redeem.
                                </Text>
                            </header>
                            <article>
                                <Label>Available Airdrops:</Label>
                            </article>
                            <section>
                                <AllAirdrops>
                                    {Airdrops.map((a, i) => (
                                        <AirdropNumber
                                            key={`airdrop-${i}`}
                                            className={
                                                activeAirdropIndex === i
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() =>
                                                setActiveAirdropIndex(i)
                                            }
                                        >
                                            Airdrop {i + 1}
                                        </AirdropNumber>
                                    ))}
                                </AllAirdrops>
                                <Amount>
                                    <section>
                                        {Number(
                                            Airdrops[activeAirdropIndex]
                                                .isClaimed
                                                ? '0'
                                                : Airdrops[activeAirdropIndex]
                                                      .amount
                                        ).toFixed(4)}{' '}
                                    </section>
                                    <section className="token">
                                        <PSDNLogoSmall />
                                        PSDN
                                    </section>
                                </Amount>
                            </section>

                            {Airdrops[0].loading ? (
                                <Loader width="40px" />
                            ) : (
                                <Button
                                    onClick={Airdrops[activeAirdropIndex].claim}
                                    disabled={
                                        !Airdrops[activeAirdropIndex]
                                            .isEligible ||
                                        Airdrops[activeAirdropIndex]
                                            .isClaimed ||
                                        Airdrops[activeAirdropIndex].loading
                                    }
                                >
                                    {Airdrops[activeAirdropIndex].isClaimed
                                        ? 'Airdrop Claimed'
                                        : 'Claim'}
                                </Button>
                            )}

                            <Summary>
                                {Airdrops.map((a, i) => (
                                    <section key={`airdrop-${i}`}>
                                        <div>Airdrop {i + 1}:</div>
                                        <div>
                                            {a.isEligible
                                                ? a.isClaimed
                                                    ? 'Claimed'
                                                    : 'Unclaimed'
                                                : 'Not Eligible'}
                                        </div>
                                    </section>
                                ))}
                            </Summary>
                        </AidropDetails>
                    </Box>
                </>
            ) : (
                <div>
                    <Title>Airdrop</Title>
                    <Text>
                        Sorry. You are not eligible for the any of the airdrops.
                    </Text>
                </div>
            )}
        </Container>
    )
}

const Summary = styled.div`
    padding: 16px;
    border: 1px dashed ${(props) => props.theme.colors.border};
    width: 100%;
    section {
        margin: 4px 0;
        display: flex;
        justify-content: space-between;
        color: ${(props) => props.theme.colors.secondary};
    }
`

const Container = styled.div`
    max-width: 720px;
    margin: auto;
    width: 100%;
    padding: 40px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        padding: 20px;
    `}
`
const AllAirdrops = styled.div`
    display: flex;
    margin: 8px;
`
const AirdropNumber = styled.div`
    padding: 8px;
    display: inline-block;
    border-radius: 8px;
    margin-right: 8px;
    cursor: pointer;
    border: 1px solid ${(props) => props.theme.colors.gradient};
    color: white;
    &.active {
        color: #060910;
        cursor: default;
        background-color: ${(props) => props.theme.colors.gradient};
    }
`

const Title = styled.h1`
    font-size: ${(props) => props.theme.font.extraLarge};
    color: ${(props) => props.theme.colors.primary};
    font-weight: 400;
    margin: 0;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: ${(props) => props.theme.font.large};
    `}
`

const Text = styled.p`
    font-size: ${(props) => props.theme.font.default};
    color: ${(props) => props.theme.colors.secondary};
`

const Label = styled.p`
    font-size: ${(props) => props.theme.font.small};
    color: ${(props) => props.theme.colors.secondary};
    margin: 10px 0;
`

const Amount = styled.div`
    font-size: ${(props) => props.theme.font.medium};
    color: ${(props) => props.theme.colors.secondary};
    border-radius: 8px;
    margin: 8px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${(props) => props.theme.colors.border};

    .token {
        display: flex;
        padding: 8px;
        background-color: ${(props) => props.theme.colors.gradient};
        color: ${(props) => props.theme.colors.neutral};
        border-radius: 8px;
        align-items: center;
        svg {
            margin-right: 4px;
        }
    }
`

const Box = styled.div`
    width: fit-content;
    background-color: ${(props) => props.theme.colors.background};
    border-style: solid;
    border-width: 1px;
    border-image: linear-gradient(-45deg, rgb(51, 238, 255), rgb(5, 18, 24)) 1;
    padding: 20px 30px;
    margin-top: 60px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        width: 100%;
    `}
`

const AidropDetails = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${({ theme }) => theme.mediaWidth.upToMedium`
        flex-direction: column;
        align-items: flex-start;
    `}
    > header {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 1px;
            background: linear-gradient(
                90deg,
                rgba(50, 163, 220, 0) 0%,
                rgb(50, 163, 220) 49.48%,
                rgba(50, 163, 220, 0) 100%
            );
        }
        p {
            margin-top: 4px;
            font-size: 16px;
        }
    }
    > article {
        margin-top: 20px;
        width: 100%;
        text-align: left;
    }
    > section {
        width: 100%;
        border: 1px solid ${(props) => props.theme.colors.border};
        border-radius: 8px;
        margin-bottom: 32px;
    }
    button {
        width: 100%;
        margin: 0 0 32px;
    }
`

export default Airdrop
