import React, { useState } from 'react'
import { useStoreActions, useStoreState } from '../../store'
import EditSafeNameContent from '../EditSafeNameContent'
import Modal from './Modal'

const EditSafeNameModal = () => {
    const { popupsModel: popupsState } = useStoreState((state) => state)
    const { popupsModel: popupsActions } = useStoreActions((state) => state)
    const [name, setName] = useState('')
    const saveName = (name: string, id: number) => {
        const safes = JSON.parse(localStorage.getItem('safes') as any) ?? []

        if (
            safes.find((safe: { id: number; name: string }) => safe.id === id)
        ) {
            safes.find(
                (safe: { id: number; name: string }) => safe.id === id
            ).name = name
        } else {
            safes.push({
                id,
                name,
            })
        }
        localStorage.setItem('safes', JSON.stringify(safes))
        popupsActions.setIsEditSafeNameModalOpen(false)
    }
    const onChangeInput = (val: string) => {
        setName(val)
    }
    return (
        <Modal
            title={`Edit ${popupsState.modalData.name}`}
            maxWidth="350px"
            isModalOpen={popupsState.isEditSafeNameModalOpen}
            closeModal={() => {
                popupsActions.setIsEditSafeNameModalOpen(false)
                popupsActions.setModalData({})
            }}
            handleSubmit={() => saveName(name, popupsState.modalData.id)}
            submitBtnText="Submit"
            showSubmitIcon={false}
            backDropClose
        >
            <EditSafeNameContent onChange={onChangeInput} />
        </Modal>
    )
}

export default EditSafeNameModal
